import { useEffect, useState } from 'react'
import { useModal } from 'components/modals/provider'
import Icon from 'components/icon/icon'
import Backdrop from 'components/backdrop/backdrop'
import styles from './root.module.scss'
import { cn } from 'utils/helpers'

export default function ModalRoot() {
    const [visible, setVisible] = useState()
    const { component: Component, modalProps, hideModal, showModal } = useModal()

    useEffect(() => {
        setVisible(!!Component)
        setTimeout(() => {}, 2000)
    }, [Component])

    return Component ? (
        <div className={cn(styles.scroll, visible && styles.visible)}>
            <Backdrop className={styles.backdropShow} onClick={hideModal} />
            <div className={styles.modal}>
                <div className={styles.cell}>
                    <Icon onClick={hideModal} className={styles.close} btn icon="x" />
                    <Component {...modalProps} hideModal={hideModal} showModal={showModal} />
                </div>
            </div>
        </div>
    ) : null
}
